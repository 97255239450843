// @ts-ignore
export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'de',
    messages: {
        en: {
            firstname: 'Firstname',
            lastname: 'Lastname',
            password: 'Password',
            startMeeting: 'Start meeting',
            required: 'Required field',
            waitingUntilStart: 'The meeting is about to start...',
            waitUntilModeratorApproves: 'Waiting for a moderator to approve your participation...',
            wrongPassword: 'Wrong room password',
            meetingNotOpenYet: 'Meeting not open yet',
            meetingWillOpen: 'Meeting will open on {date}',
            meetingClosed: 'Meeting closed',
            keepMeetingOpen: 'Keep meeting open',
            meetingClosedText: 'The meeting is already closed.',
            meetingLeft: 'Meeting left',
            meetingLeftText: 'You left the meeting. Thank you for your time.',
            moderatorSettings: 'Moderator Settings',
            minutesTillClosing: 'Minutes until aut. closing',
            allowScreenshare: 'Screenshare allowed',
            muteParticipants: 'Mute participants',
            participantInWaitingRoom: '{name} wants to join the meeting',
            participantInWaitingRoomDescription: 'Approve or reject the participant',
            permitEntry: 'Permit entry',
            denyEntry: 'Deny entry',
            dsgvo: 'I agree to the <a href="{link}" target="_blank" rel="noopener">privacy policy</a>.',
            mute: 'Micro off',
            unmute: 'Micro on',
            videoOff: 'Camera off',
            videoOn: 'Camera on',
            disconnect: 'Disconnect',
            finishMeeting: 'Finish meeting',
            finishMeetingDescription: 'Are you sure you want to end the meeting for everyone?',
            shareScreen: 'Share',
            stopShareScreen: 'Stop Share',
            more: 'More',
            participants: 'participants',
            you: '(You)',
            background: 'Background',
            settings: 'Settings',
            members: 'Participants',
            camera: 'Camera',
            audio: 'Audio',
            chooseBackground: 'Choose backgorund',
            validationError: "Validation error",
            permissionDeniedTitle: "Camera and Microphone Access Required",
            permissionDeniedMessage: "To participate in the consultation with image and sound, please allow access to your camera and microphone. Change this in the browser settings and reload the page.",
            redirectErrorTitle: 'Redirect Error',
            redirectErrorMessage: 'An error occurred while redirecting. Please try again.',
            allowCameraAndMic: "Allow camera and microphone access",
            allowCameraAndMicDescription: "Please allow access to the camera and microphone by clicking the button below.<br>This is required for participation in the consultation.",
            cameraAndMicGranted: "Camera and microphone access has been successfully granted. You can now proceed.",
            cameraAndMicDenied: "Access to the camera and microphone was denied. Please allow access via browser settings to proceed.",
            accessGranted: "Access granted",
            accessDenied: "Access denied",
            endPrivacyMode: "Exit privacy mode",
            banUserDescription: "Are you sure you want to ban this user? The user will not be able to rejoin.",
            banUser: 'Ban',
            systemRequirementsTitle: "Unsupported device or browser",
            systemRequirementsDescription: `
                      <p>It looks like your current device or browser is not supported by our meeting platform.
                      To join our meeting, please try one of the following browsers:</p>
                      <br>
                      <ul>
                          <li>Google Chrome (Version 93 or higher)</li>
                          <li>Mozilla Firefox (Version 91 or higher)</li>
                          <li>Microsoft Edge (Version 94 or higher)</li>
                          <li>Safari (Version 15 or higher)</li>
                      </ul>
                      <br>
                      <p>If you continue to have problems, please contact our support at {link}</p>
                      <br>
                      <p>Thank you for your understanding and patience!</p>`,
            activeMediaErrorTitle: 'An error occurred',
            activeMediaErrorMessage: 'An error occurred while activating the media. Please refresh the page and try again.',
        },
        de: {
            firstname: 'Vorname',
            lastname: 'Nachname',
            password: 'Passwort',
            startMeeting: 'Meeting starten',
            required: 'Pflichtfeld',
            waitingUntilStart: 'Das Meeting startet gleich...',
            waitUntilModeratorApproves: 'Warten bis ein Moderator die Teilnahme genehmigt...',
            wrongPassword: 'Falsches Passwort',
            meetingNotOpenYet: 'Meeting ist noch geschlossen',
            meetingWillOpen: 'Das Meeting öffnet am {date}',
            meetingClosed: 'Meeting geschlossen',
            keepMeetingOpen: 'Meeting offenhalten',
            meetingClosedText: 'Das Meeting ist bereits geschlossen.',
            meetingLeft: 'Meeting verlassen',
            meetingLeftText: 'Sie haben das Meeting verlassen. Vielen Dank für Ihre Teilnahme.',
            moderatorSettings: 'Moderator Einstellungen',
            minutesTillClosing: 'Minuten bis aut. Schließung',
            allowScreenshare: 'Screenshare zulassen',
            muteParticipants: 'Teilnehmer stummschalten',
            participantInWaitingRoom: '{name} möchte dem Meeting beitreten',
            participantInWaitingRoomDescription: 'Teilnehmer genehmigen oder ablehnen',
            permitEntry: 'Zulassen',
            denyEntry: 'Ablehnen',
            dsgvo: 'Ich erkläre mich mit den <a href="{link}" target="_blank" rel="noopener">Datenschutzbestimmungen</a> einverstanden.',
            mute: 'Micro aus',
            unmute: 'Micro ein',
            videoOff: 'Kamera aus',
            videoOn: 'Kamera ein',
            disconnect: 'Meeting verlassen',
            finishMeeting: 'Meeting beenden',
            finishMeetingDescription: 'Sind Sie sicher, dass Sie das Meeting für alle beenden möchten?',
            shareScreen: 'Teilen',
            stopShareScreen: 'Teilen beenden',
            more: 'Mehr',
            participants: 'Teilnehmer',
            you: '(Du)',
            background: 'Hintergrund',
            settings: 'Einstellungen',
            members: 'Teilnehmer',
            camera: 'Kamera',
            audio: 'Audio',
            chooseBackground: 'Hintergrund auswählen',
            validationError: "Validierungsfehler",
            permissionDeniedTitle: "Zugriff auf Kamera und Mikrofon benötigt",
            permissionDeniedMessage: "Um an der Beratung mit Bild und Ton teilnehmen zu können, erlauben Sie bitte den Zugriff auf Kamera und Mikrofon. Ändern Sie dies in den Browsereinstellungen und laden Sie die Seite neu.",
            redirectErrorTitle: 'Fehler beim Weiterleiten',
            redirectErrorMessage: 'Es ist ein Fehler beim Weiterleiten aufgetreten. Bitte versuchen Sie es erneut.',
            allowCameraAndMic: 'Kamera- und Mikrofonzugriff erlauben',
            allowCameraAndMicDescription: 'Bitte gestatten Sie den Zugriff auf Kamera und Mikrofon durch Klick auf den nachfolgenden Button.<br>Dies ist für eine Teilnahme an der Beratung erforderlich.',
            cameraAndMicGranted: "Kamera- und Mikrofonzugriff wurde erfolgreich erteilt. Sie können jetzt fortfahren.",
            cameraAndMicDenied: "Der Zugriff auf Kamera und Mikrofon wurde verweigert. Bitte erlauben Sie den Zugriff über die Browsereinstellungen, um fortzufahren.",
            accessGranted: "Zugriff erlaubt",
            accessDenied: "Zugriff verweigert",
            endPrivacyMode: "Datenschutzmodus beenden",
            banUserDescription: "Sind Sie sicher, dass Sie diesen Benutzer sperren möchten? Der Benutzer kann nicht wieder beitreten.",
            banUser: 'Bannen',
            systemRequirementsTitle: 'Nicht unterstütztes Gerät oder Browser',
            systemRequirementsDescription: `
                      <p>Es sieht so aus, als ob Ihr aktuelles Gerät oder Browser nicht von unserer Meeting-Plattform unterstützt wird.
                      Um an unserem Meeting teilzunehmen, probieren Sie bitte einen der folgenden Browser aus:</p>
                      <br>
                      <ul>
                          <li>Google Chrome (Version 93 oder höher)</li>
                          <li>Mozilla Firefox (Version 91 oder höher)</li>
                          <li>Microsoft Edge (Version 94 oder höher)</li>
                          <li>Safari (Version 15 oder höher)</li>
                      </ul>
                      <br>
                      <p>Falls Sie weiterhin Probleme haben, kontaktieren Sie bitte unseren Support unter {link}</p>
                      <br>
                      <p>Vielen Dank für Ihr Verständnis und Ihre Geduld!</p>
                  `,
            activeMediaErrorTitle: 'Ein Fehler ist aufgetreten',
            activeMediaErrorMessage: 'Ein Fehler ist beim Aktivieren der Kamera aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
        },
        it: {
            firstname: 'Nome',
            lastname: 'Cognome',
            password: 'Password',
            startMeeting: 'Inizia la riunione',
            required: 'Campo obbligatorio',
            waitingUntilStart: 'La riunione sta per iniziare...',
            waitUntilModeratorApproves: 'In attesa dell\'approvazione del moderatore per la tua partecipazione...',
            wrongPassword: 'Password della stanza errata',
            meetingNotOpenYet: 'Riunione non ancora aperta',
            meetingWillOpen: 'La riunione si aprirà il {date}',
            meetingClosed: 'Riunione chiusa',
            keepMeetingOpen: 'Mantieni la riunione aperta',
            meetingClosedText: 'La riunione è già terminata.',
            meetingLeft: 'Riunione abbandonata',
            meetingLeftText: 'Hai lasciato la riunione. Grazie per il tuo tempo.',
            moderatorSettings: 'Impostazioni del moderatore',
            minutesTillClosing: 'Minuti alla chiusura aut.',
            allowScreenshare: 'Condivisione schermo consentita',
            muteParticipants: 'Silenzia i partecipanti',
            participantInWaitingRoom: '{name} vuole unirsi alla riunione',
            participantInWaitingRoomDescription: 'Approva o rifiuta il partecipante',
            permitEntry: 'Permetti l\'ingresso',
            denyEntry: 'Nega l\'ingresso',
            dsgvo: 'Accetto la <a href="{link}" target="_blank" rel="noopener">politica sulla privacy</a>.',
            mute: 'Micro off',
            unmute: 'Micro su',
            videoOff: 'Ferma Video',
            videoOn: 'Avvia Video',
            disconnect: 'Disconnetti',
            finishMeeting: 'Fine riunione',
            finishMeetingDescription: 'Sei sicuro di voler terminare la riunione per tutti?',
            shareScreen: 'Condividi Schermo',
            stopShareScreen: 'Ferma Condivisione',
            more: 'Altro',
            participants: 'partecipanti',
            you: '(Tu)',
            background: 'Sfondo',
            settings: 'Impostazioni',
            members: 'Partecipanti',
            camera: 'Telecamera',
            audio: 'Audio',
            chooseBackground: 'Scegli lo sfondo',
            validationError: "Errore di validazione",
            permissionDeniedTitle: "Accesso a fotocamera e microfono richiesto",
            permissionDeniedMessage: "Per partecipare alla consultazione con immagini e suoni, si prega di consentire l'accesso alla fotocamera e al microfono. Modificare questa impostazione nelle impostazioni del browser e ricaricare la pagina.",
            redirectErrorTitle: 'Errore di reindirizzamento',
            redirectErrorMessage: 'Si è verificato un errore durante il reindirizzamento. Si prega di riprovare.',
            allowCameraAndMic: "Consenti l'accesso alla fotocamera e al microfono",
            allowCameraAndMicDescription: "Si prega di consentire l'accesso alla fotocamera e al microfono cliccando il pulsante sottostante.<br>Questo è necessario per partecipare alla consultazione.",
            cameraAndMicGranted: "L'accesso alla fotocamera e al microfono è stato concesso con successo. Ora puoi procedere.",
            cameraAndMicDenied: "L'accesso alla fotocamera e al microfono è stato negato. Si prega di consentire l'accesso tramite le impostazioni del browser per procedere.",
            accessGranted: "Accesso consentito",
            accessDenied: "Accesso negato",
            endPrivacyMode: "Esci dalla modalità privacy",
            banUserDescription: "Sei sicuro di voler bannare questo utente? L'utente non potrà più partecipare.",
            banUser: 'Eliminare',
            systemRequirementsTitle: "Dispositivo o browser non supportato",
            systemRequirementsDescription: `
                      <p>Sembra che il tuo dispositivo o browser attuale non sia supportato dalla nostra piattaforma di meeting.
                      Per partecipare al nostro meeting, prova uno dei seguenti browser:</p>
                      <br>
                      <ul>
                          <li>Google Chrome (Versione 93 o superiore)</li>
                          <li>Mozilla Firefox (Versione 91 o superiore)</li>
                          <li>Microsoft Edge (Versione 94 o superiore)</li>
                          <li>Safari (Versione 15 o superiore)</li>
                      </ul>
                      <br>
                      <p>Se continui ad avere problemi, contatta il nostro supporto all'indirizzo {link}</p>
                      <br>
                      <p>Grazie per la tua comprensione e pazienza!</p>`,
            activeMediaErrorTitle: 'Si è verificato un errore',
            activeMediaErrorMessage: 'Si è verificato un errore durante l\'attivazione dei media. Si prega di aggiornare la pagina e riprovare.',

        },
    }
}))